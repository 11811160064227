import React from "react"

const NoNetwork = () => (
  <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M48.875 6.375H19.125C16.7778 6.375 14.875 8.27779 14.875 10.625V40.375C14.875 42.7222 16.7778 44.625 19.125 44.625H48.875C51.2222 44.625 53.125 42.7222 53.125 40.375V10.625C53.125 8.27779 51.2222 6.375 48.875 6.375Z"
      fill="white"
      stroke="#AEB3B7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.0834 38.25C41.8658 38.25 42.5 38.8843 42.5 39.6667V41.0833C44.0648 41.0833 45.3334 42.3519 45.3334 43.9167V58.0833C45.3334 59.6481 44.0648 60.9167 42.5 60.9167H38.25V65.1667H31.8278V60.9167H26.9167C25.3519 60.9167 24.0834 59.6481 24.0834 58.0833V43.9167C24.0834 42.3519 25.3519 41.0833 26.9167 41.0833V39.6667C26.9167 38.8843 27.551 38.25 28.3334 38.25H41.0834Z"
      fill="#35414A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.7954 12.75C40.5778 12.75 41.2121 13.3843 41.2121 14.1667L41.2108 16.7294L43.9166 16.7296C44.699 16.7296 45.3333 17.3639 45.3333 18.1463V34C45.3333 34.7824 44.699 35.4167 43.9166 35.4167L43.272 35.4152L43.2727 33.3403H41.2121L41.2108 35.4152H39.151L39.1515 33.3403H37.0909L37.0897 35.4152H35.0299L35.0303 33.3403H32.9697L32.9686 35.4152H30.9088L30.909 33.3403H28.8484L28.8475 35.4152H26.7877L26.7878 33.3403H24.7272L24.7265 35.4152L24.0833 35.4167C23.3009 35.4167 22.6666 34.7824 22.6666 34V18.1463C22.6666 17.3639 23.3009 16.7296 24.0833 16.7296L26.7877 16.7294L26.7878 14.1667C26.7878 13.3843 27.4221 12.75 28.2045 12.75H39.7954Z"
      fill="#35414A"
    />
  </svg>
)

export default NoNetwork
